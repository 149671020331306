<template>
  <div class="userpage">
    <v-toolbar flat dense>
      <v-toolbar-title>My Business Profile</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn color="primary" x-small text @click.stop="bizDialog = true">
        edit business profile
      </v-btn>
      <UpdateBiz
        :bizDialog="bizDialog"
        @closeBiz="closeBiz"
        @submitBiz="submitBiz"
      />
    </v-toolbar>
    <v-divider></v-divider>
    <v-card flat class="profile">
      <v-card-text>
        <v-simple-table>
          <tbody class="business">
            <tr>
              <td>
                <ul>
                  <li>Business Name:</li>
                  <li>
                    {{ details.business_name }}
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Business Email:</li>
                  <li>{{ details.business_email }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>phone:</li>
                  <li>{{ details.phone }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>Country:</li>
                  <li>{{ details.country }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>region/state:</li>
                  <li>{{ details.regions }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>district:</li>
                  <li>{{ details.districts }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>physical location:</li>
                  <li>{{ details.physical_address }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>city:</li>
                  <li>{{ details.city }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>postal address:</li>
                  <li>{{ details.postal_address }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>postal address:</li>
                  <li>{{ details.postal_address }}</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>geo location:</li>
                  <li>
                    {{ details.geolocation }}
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn color="primary" icon v-on="on">
                          <v-icon>mdi-earth</v-icon>
                        </v-btn>
                      </template>
                      <span>View on Google Map</span>
                    </v-tooltip>
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>rating:</li>
                  <li>
                    <v-rating
                      :value="details.ratings"
                      background-color="#c8cdd7"
                      color="success"
                      hover
                      length="5"
                      readonly
                    ></v-rating>
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>
                <ul>
                  <li>date created:</li>
                  <li>{{ details.created | myDating }}</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>last updated:</li>
                  <li>{{ details.updated | myDating }}</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-card-text>
    </v-card>
    <v-snackbar
      v-model="snack.bar"
      :color="snack.color"
      :multi-line="snack.multiLine"
    >
      {{ snack.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snack.bar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import Restful from "@/services/RestFul";
import Utils from "@/mixins/utils.js";
import UpdateBiz from "../../../components/accounts/profile_business.vue";

export default {
  components: { UpdateBiz },
  mixins: [Utils],
  data: () => ({
    snack: {
      color: "info",
      multiLine: true,
      bar: false,
      text: "Processing ... ",
    },
    details: {},
    bizDialog: false,
  }),
  created() {
    this.detail();
  },
  methods: {
    detail() {
      this.loading = true;
      let self = this;
      Restful.subscribers
        .detail()
        .then((response) => {
          console.log(response.data);
          this.details = response.data;
          this.profilia = response.data.profile;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
          self.loading = false;
        });
    },
    closeBiz(p) {
      console.log(p);
      this.bizDialog = p.state;
    },
    submitBiz(p) {
      this.snack.bar = true;
      console.log(p);
      let self = this;
      Restful.subscribers
        .update(p.data)
        .then((response) => {
          // console.log(response.data);
          this.snack.color = "success";
          this.snack.text = response.data;
          this.detail();
        })
        .catch((error) => {
          console.log(error.response);
          self.snack.color = "error";
          self.snack.text = error.response.data;
        });
      this.bizDialog = p.state;
    },
  },
};
</script>
