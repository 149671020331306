<template>
  <v-dialog v-model="bizDialog" max-width="700" persistent>
    <v-card :loading="loading" loader-height="2">
      <v-card-title>Update Business Profile</v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="create.business_name"
                dense
                label="business name"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="create.business_email"
                dense
                label="business email"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="create.physical_address"
                dense
                label="physical address"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="create.geolocation"
                dense
                label="geo-location"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="create.phone"
                dense
                label="phone"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="create.postal_address"
                dense
                label="postal address"
            ></v-text-field>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-select
                v-model="create.countryid"
                :items="countrylist"
                dense
                item-text="name"
                item-value="id"
                label="countryid"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-select
                v-model="create.regionsid"
                :items="regionlist"
                dense
                item-text="name"
                item-value="id"
                label="regionsid"
            ></v-select>
          </v-col>
        </v-row>

        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-select
                v-model="create.districtsid"
                :items="districtlist"
                dense
                item-text="name"
                item-value="id"
                label="district"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
                v-model="create.city"
                dense
                label="city"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span class="error_message">{{ responseMessage }}</span>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn color="red" small text @click="closeDialog" v-on="on">
              cancel
            </v-btn>
          </template>
          <span>cancel</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
                :disabled="$v.create.$invalid"
                color="blue"
                small
                text
                @click="submit"
                v-on="on"
            >
              submit
            </v-btn>
          </template>
          <span>Submit</span>
        </v-tooltip>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Restful from "@/services/RestFul";
import {validationMixin} from "vuelidate";
// import moment from "moment";
import {decimal, email, required} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  props: {
    bizDialog: Boolean,
  },
  // mixins: [Utils],
  data() {
    return {
      responseMessage: null,
      datalist: [],
      loading: false,
      menu2: false,
      countrylist: [],
      regionlist: [],
      districtlist: [],
      create: {
        business_name: null,
        business_email: null,
        physical_address: null,
        geolocation: null,
        phone: null,
        postal_address: null,
        countryid: null,
        regionsid: null,
        districtsid: null,
        city: null,
      },
    };
  },
  validations: {
    create: {
      business_name: {
        required,
      },
      business_email: {
        required,
        email,
      },
      physical_address: {
        required,
      },
      geolocation: {
        required,
      },
      postal_address: {
        required,
      },
      countryid: {
        decimal,
        required,
      },
      regionsid: {
        required,
        decimal,
      },
      districtsid: {
        required,
        decimal,
      },
      city: {
        required,
      },
    },
  },
  watch: {
    "create.countryid": function (a) {
      if (a > 0) {
        this.region(a);
      }
    },
    "create.regionsid": function (a) {
      if (a > 0) {
        this.district(a);
      }
    },
  },
  created() {
    this.find();
    this.country();
  },
  methods: {
    find() {
      this.loading = true;
      let self = this;
      Restful.subscribers
          .detail()
          .then((response) => {
            console.log(response.data);
            //
            this.create.business_name = response.data.business_name;
            this.create.business_email = response.data.business_email;
            this.create.physical_address = response.data.physical_address;
            this.create.geolocation = response.data.geolocation;
            this.create.phone = response.data.phone;
            this.create.postal_address = response.data.postal_address;
            this.create.countryid = response.data.countryid;
            this.create.regionsid = response.data.regionsid;
            this.create.districtsid = response.data.districtsid;
            this.create.city = response.data.city;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    country() {
      this.loading = true;
      let self = this;
      Restful.general.country
          .list()
          .then((response) => {
            console.log(response.data);
            this.countrylist = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    region(id) {
      this.loading = true;
      let self = this;
      Restful.general.regions
          .byCountry(id)
          .then((response) => {
            console.log(response.data);
            this.regionlist = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    district(id) {
      this.loading = true;
      let self = this;
      Restful.general.district
          .byRegion(id)
          .then((response) => {
            console.log(response.data);
            this.districtlist = response.data;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error.response);
            self.loading = false;
          });
    },
    closeDialog() {
      let payload = {state: false};
      this.$emit("closeBiz", payload);
    },
    submit() {
      let business_name = this.create.business_name;
      let business_email = this.create.business_email;
      let physical_address = this.create.physical_address;
      let geolocation = this.create.geolocation;
      let phone = this.create.phone;
      let postal_address = this.create.postal_address;
      let countryid = this.create.countryid;
      let regionsid = this.create.regionsid;
      let districtsid = this.create.districtsid;
      let city = this.create.city;

      let data = {
        business_name,
        business_email,
        physical_address,
        geolocation,
        phone,
        postal_address,
        countryid,
        regionsid,
        districtsid,
        city,
      };
      // console.log(data);

      let payload = {state: false, data: data};
      this.$emit("submitBiz", payload);
    },
  },
};
</script>
